.brand-carousel {
  overflow: hidden;
/* center it remove the below css in this div once more brands are added*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.marquee-logo-container > div {
  display: flex;
  flex-direction: row;
  overflow-x:scroll;
  scroll-behavior: smooth;
  scrollbar-width: none; 
  white-space: nowrap;


}

.marquee-logo {
  max-width: 100px; 
  height: auto;
}


.marquee-portfolio-header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 4rem;
  padding: 3rem;
}
